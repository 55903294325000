import React from "react"
import PropTypes from "prop-types"
import Image from 'gatsby-image'
import {Link} from 'gatsby'

const BlogList = ({blog}) => {
  const replaceHtmlContent = description => {
    let shortDescription = description.substr(0, description.indexOf("."))
    shortDescription = shortDescription.toString();
    shortDescription = shortDescription.replace(/<[^>]*>/g, '');
    return shortDescription;
  };
  return (
    <div>
      <div className="BlogList">
        {blog.map(({node})=>{
          const {id,name,blogby,image,tags,description,slug}=node
          return(
            <div className="listView" key={id}>
          <div className="listImgCard">
            <Image fluid={image.fluid} alt={name} className="img-fluid" />
          </div>
          <div className="BlogInfo">
            <h4 className="BlogTitle">
            <Link to={`blogs/${slug}`}>{name}</Link>
            </h4>
            <p className="BlogDesc">
            {replaceHtmlContent(description.description)}
            </p>
            <h6 className="BlogBy">{blogby}</h6>
            <div className="tagsRow">
              {tags.map(tagsData=>(
                <Link to={tagsData.link} key={tagsData.name} className="tag">{tagsData.name}</Link>
              ))}
            </div>
          </div>
        </div>
          )
        })}
      </div>
    </div>
  )
}

BlogList.propTypes = {
  blog:PropTypes.array
}

export default BlogList
