import React from "react"
import { Link } from 'gatsby'
import { BLOG_LINKS } from '../../constant/Link'

const BlogFilter = ({setFilter,filter}) => {
  const showActive=(id)=>{
    if(filter===id){
      return{backgroundColor: '#8cc749', color: '#ffffff'}
    }
  }
    return(
        <div className="Filters">
                <select className="custom-select" defaultValue="0">
                  <option value="0">Sort by</option>
                  <option value="1">Recent</option>
                  <option value="2">Popular</option>
                  <option value="3">New</option>
                </select>
                <div className="FilterBtns">
                  {BLOG_LINKS.map(links=>(
                    <Link
                    key={links.id} 
                    to={links.url} 
                    style={showActive(links.id)} 
                    className="BtnFilter"
                    onClick={()=>setFilter(links.id)}>
                    {links.name}
                  </Link>
                  ))}
                </div>
              </div>
    )
}

export default BlogFilter