import React from "react"
import PropTypes from "prop-types"
import Image from 'gatsby-image'
import {Link} from 'gatsby'

const BlogRow = ({blog}) => {
  return (
    <div>
      {blog.map(({node})=>{
        const {id,name,blogby,image,tags,description,slug}=node
        const replaceHtmlContent = () => {
          let shortDescription = description.description.substr(0, description.description.indexOf("."))
          shortDescription = shortDescription.toString();
          shortDescription = shortDescription.replace(/<[^>]*>/g, '');
          return shortDescription;
        };
        return(
          <div className="BlogRow" key={id}>
        <div className="BlogCard">
          <div className="BlogImg">
            <Image fluid={image.fluid} alt={name} />
          </div>
          <div className="BlogInfo">
            <h6 className="BlogBy">{blogby}</h6>
            <div className="tagsRow">
              {tags.map(tagData=>(
                <Link to={tagData.link} key={tagData.name} className="tag">{tagData.name}</Link>
              ))}
            </div>
            <h4 className="BlogTitle">
            <Link to={`blogs/${slug}`}>{name}</Link>
            </h4>
            <p className="BlogDesc">
              {replaceHtmlContent()}
            </p>
          </div>
        </div>
      </div>
        )
      })}
    </div>
  )
}

BlogRow.propTypes = {
  blog:PropTypes.array
}

export default BlogRow
