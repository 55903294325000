import { graphql, useStaticQuery } from "gatsby"

const BlogQuery = () => useStaticQuery(graphql`
query{
    blog:allContentfulBrainyFiedBlog{
      edges{
        node{
          id
          slug
          name
          blogby
          image{
            fluid{
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags{
            link
            name
          }
          description{
            description
          }
        }
      }
    }
  }`)

  export default BlogQuery