import React, { useState } from "react"
import PropTypes from "prop-types"

import BlogRow from "./BlogRow"
import BlogList from "./BlogList"
import BlogGrid from "./BlogGrid"
import BlogFilter from "./BlogFilter"
import BlogSideBox from "./BlogSideBox"

const BlogPage = ({blog}) => {
  const [filter,setFilter]=useState('list')
  const showBlog=()=>{
    if(filter==='list'){
      return <BlogList blog={blog}/>
    }else if(filter==='module'){
      return <BlogGrid blog={blog}/>
    }else if(filter==='stream'){
      return <BlogRow blog={blog}/>
    }
  }
  return (
    <div>
      <div className="BlogPage">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9">
              <BlogFilter setFilter={setFilter} filter={filter} />
              {showBlog()}
            </div>
            <div className="col-xs-12 col-sm-5 col-md-4 col-lg-3">
              <BlogSideBox />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BlogPage.propTypes = {
  blog: PropTypes.array,
}

export default BlogPage
