import React from "react"
import PropTypes from "prop-types"
import Image from 'gatsby-image'
import {Link} from 'gatsby'

const BlogGrid = ({blog}) => {
  const replaceHtmlContent = description => {
    let shortDescription = description.substr(0, description.indexOf("."))
    shortDescription = shortDescription.toString();
    shortDescription = shortDescription.replace(/<[^>]*>/g, '');
    return shortDescription;
  };
  return (
    <div>
      <div className="BlogGrid">
        <div className="row">
          {blog.map(({node})=>{
            const {id,name,blogby,image,tags,description,slug}=node
          return(
            <div className="col-md-6" key={id}>
            <div className="blogCard">
              <div className="blogIMG">
                <Image fluid={image.fluid} className="img-fluid" alt={name} />
              </div>
              <div className="BlogInfo">
                <h6 className="BlogBy">{blogby}</h6>
                <h4 className="BlogTitle">
                  <Link to={`blogs/${slug}`}>{name}</Link>
                </h4>
                <p className="BlogDesc">
                  {replaceHtmlContent(description.description)}
                </p>
                <div className="tagsRow">
                  {tags.map(tagData=>(
                    <Link to={tagData.link} key={tagData.name} className="tag">{tagData.name}</Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          )})}
        </div>
      </div>
    </div>
  )
}

BlogGrid.propTypes = {
  blog: PropTypes.array
}

export default BlogGrid
