import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPage from "../components/Blog/BlogPage"
import BlogQuery from '../query/BlogQuery'
const Blogs = () => {
  const { blog }= BlogQuery()
  return (
    <Layout>
      <SEO title="Blogs" />
      <BlogPage blog={blog.edges}/>
    </Layout>
  )
}

export default Blogs
